<template>
  <div
    class="cart-info-card-wrapper d-flex d-md-none align-center grey lighten-1 rounded-md py-3"
    v-if="isAuthenticated"
  >
    <v-btn
      :aria-label="serviceName"
      text
      @click="openAddressSelector"
      class="service-button"
      height="50"
    >
      <v-icon large class="service-icon">
        {{ "$" + $t(`navbar.service.${getDeliveryServiceId}.fontIcon`) }}
      </v-icon>
    </v-btn>

    <div
      class="cart-info-address-card-wrapped nav-selectors d-flex flex-column"
      v-if="!isFast"
    >
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openAddressSelector"
      >
        <span class="info-text font-weight-bold">{{ shippingAddress }}</span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openTimeslotSelector"
      >
        <span class="info-text font-weight-regular text-lowercase">
          {{ timeslot }} </span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
    </div>
    <div
      class="cart-info-address-card-wrapped nav-selectors d-flex flex-column"
      v-else
    >
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openAddressSelector"
      >
        <span class="info-text font-weight-bold">{{ shippingAddress }}</span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openTimeslotSelector"
      >
        <span class="info-text font-weight-regular text-lowercase">
          {{ isFast ? timeslot.split("-")[0] : timeslot }} </span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <div class="d-flex mt-1 mt-md-0 ml-1 ml-md-0">
        <span class="rounded-sm expiry-wrapper white--text">
          <p class="px-4 mb-0 text-uppercase">
            <strong style="font-size: 12px"
              >Scade tra {{ minutesToFastTimeslot }} min</strong
            >
          </p>
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.cart-info-card-wrapper {
  .expiry-wrapper {
    background-color: #d69333;
  }
  .nav-selectors,
  .delivery-btn .v-btn__content {
    width: 100%;
    overflow: hidden;
  }
  .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";

export default {
  name: "CartInfoCardWrapper",
  mixins: [cartInfo, login],
  data() {
    return {
      minutesToFastTimeslot: 0
    };
  },
  methods: {
    startFastTimeslotCountdown() {
      let _this = this;
      const interval = setInterval(() => {
        _this.minutesToFastTimeslot = this.$dayjs().diff(
          _this.cart.timeslot.date + " " + _this.cart.timeslot.beginTime,
          "minute",
          true
        );

        _this.minutesToFastTimeslot = Math.abs(_this.minutesToFastTimeslot);

        if (_this.minutesToFastTimeslot <= 0) {
          clearInterval(interval);
          return;
        }

        _this.minutesToFastTimeslot = Math.floor(_this.minutesToFastTimeslot);

        console.log(_this.minutesToFastTimeslot);
      }, 60000);
    },
    isFast() {
      let fastIds = [13, 14, 15, 16, 17, 18];
      return fastIds.includes(this.cart.shippingAddress.deliveryServiceId);
    }
  },
  mounted() {
    if (this.isFast) this.startFastTimeslotCountdown();
  }
};
</script>
